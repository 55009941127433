// node modules
import React from 'react'
import { graphql, PageProps } from 'gatsby'

// layout
import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

// hooks
import usePopup from 'hooks/usePopup'

// components
import Hero from 'components/pages/about/hero'
import Breadcrumbs from 'components/shared/breadcrumbs'
import About from 'components/pages/about/about'

const ONasPage: React.FC<PageProps<Queries.OnasPageQuery>> = ({ data }) => {
  const PAGE = data.wpPage?.oNas
  const PAGE_SEO = data.wpPage?.seo!

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const POPUP = {
    image: {
      src: PAGE?.popupImg?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: PAGE?.popupImg?.altText ?? '',
    },
    delay: PAGE?.popupDelay,
    link: PAGE?.popupLink ?? null,
    visibleOnMobile: PAGE?.popupVisibleOnMobile ?? false,
  }

  usePopup(POPUP)

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Hero
        heading={PAGE?.aboutUsBannerTitle!}
        image={{
          src: PAGE?.aboutUsBannerHeroImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.aboutUsBannerHeroImg?.altText!,
        }}
      />
      <Breadcrumbs crumbs={[{ name: data?.wpPage?.title! }]} />
      <About
        firstHeading={PAGE?.aboutUsTechnologyTitle!}
        secondHeading={PAGE?.aboutUsConcentrationTitle!}
        firstSubheading={PAGE?.aboutUsTechnologySubtitle!}
        secondSubheading={PAGE?.aboutUsConcentrationSubtitle!}
        firstVideoUrl={PAGE?.aboutUsTechnologyFilm!}
        secondVideoUrl={PAGE?.aboutUsConcentrationFilm!}
        firstDescription={PAGE?.aboutUsTechnologyDescription!}
        secondDescription={PAGE?.aboutUsConcentrationDescription!}
      />
    </Layout>
  )
}

export default ONasPage

export const query = graphql`
  query OnasPage {
    wpPage(slug: { regex: "/o-nas/" }) {
      title
      oNas {
        aboutUsTechnologyTitle
        aboutUsTechnologySubtitle
        aboutUsTechnologyDescription
        aboutUsTechnologyFilm
        aboutUsBannerTitle
        aboutUsConcentrationTitle
        aboutUsConcentrationSubtitle
        aboutUsConcentrationDescription
        aboutUsConcentrationFilm
        aboutUsBannerHeroImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                breakpoints: [1280, 1920]
              )
            }
          }
        }
        popupImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        popupDelay
        popupLink
        popupVisibleOnMobile
      }
      seo {
        ...WpSEO
      }
    }
  }
`
