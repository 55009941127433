import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'

import media from 'styles/media'

const StyledContainer = styled(Container)`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 500;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
`

const Separator = styled.span`
  padding: 0 10px;
`

const CrumbWrapper = styled.div`
  display: flex;

  ${media.sm.max} {
    display: none;

    &:nth-last-of-type(1),
    &:nth-last-of-type(2) {
      display: flex;
    }
  }
`

type Crumb = {
  name: string
  to?: string
}

type Props = {
  crumbs: Crumb[]
}

const Breadcrumbs: React.FC<Props> = ({ crumbs }) => {
  const allCrumbs = [{ name: 'Strona główna', to: '/' }, ...crumbs]

  return (
    <StyledContainer>
      {allCrumbs.map(({ name, to }, index) => {
        const isLast = index === allCrumbs.length - 1
        return (
          <CrumbWrapper key={name}>
            <StyledLink as={!to ? 'div' : Link} to={to}>
              <Text transform="uppercase">{name}</Text>
            </StyledLink>
            {!isLast && <Separator>/</Separator>}
          </CrumbWrapper>
        )
      })}
    </StyledContainer>
  )
}

export default Breadcrumbs
