import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import ButtonWithIcon from 'components/shared/button-with-icon'
import Player from 'components/shared/player'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

const StyledContainer = styled(Container)`
  ${media.lg.max} {
    padding: 0;
  }
`

const Wrapper = styled.div`
  ${media.lg.min} {
    padding-bottom: 50px;
  }
`

const FirstSectionWrapper = styled.div`
  padding-top: 50px;
`

const IntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 22px;

  p {
    order: 1;
  }

  ${media.lg.min} {
    padding: 0;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    p {
      order: 0;
    }
  }
`

const StyledHeading = styled(Heading)`
  ${media.lg.min} {
    width: 50%;
  }
`

const StyledSubheading = styled(Text)`
  font-size: 22px;
  margin-top: 20px;

  ${media.lg.min} {
    margin-top: 0;
    font-size: 30px;
  }
`

const PlayerWrapper = styled.div`
  height: 60vh;

  ${media.lg.min} {
    height: auto;
    width: 65%;
    margin-top: 20px;
  }
`

const DescriptionWrapper = styled.div`
  padding: 50px 22px 100px 22px;

  p {
    line-height: 22px;
  }

  ${media.lg.min} {
    padding: 75px 0;
    margin-left: 50%;
    width: 35%;

    p {
      line-height: 26px;
    }
  }
`

const SecondSectionWrapper = styled.div`
  ${media.lg.min} {
    ${StyledHeading} {
      order: -1;
      margin-left: 20%;
    }

    ${PlayerWrapper} {
      margin-left: auto;
    }

    ${DescriptionWrapper} {
      margin-left: 35%;
      width: 45%;
    }
  }
`

type Props = {
  firstHeading: string
  secondHeading: string
  firstSubheading: string
  secondSubheading: string
  firstVideoUrl: string
  secondVideoUrl: string
  firstDescription: string
  secondDescription: string
}

const About: React.FC<Props> = ({
  firstHeading,
  secondHeading,
  firstSubheading,
  secondSubheading,
  firstVideoUrl,
  secondVideoUrl,
  firstDescription,
  secondDescription,
}) => {
  const { lg } = useBreakpoint()

  return (
    <StyledContainer>
      <Wrapper>
        <FirstSectionWrapper id="cfmoto-global">
          <IntroWrapper>
            <StyledSubheading
              transform="uppercase"
              as="h1"
              weight={700}
              dangerouslySetInnerHTML={{ __html: firstSubheading }}
            />
            <StyledHeading
              size={67}
              as="h2"
              transform="uppercase"
              line="100%"
              dangerouslySetInnerHTML={{ __html: firstHeading }}
            />
          </IntroWrapper>
          <PlayerWrapper>
            <Player videoSrcUrl={firstVideoUrl} />
          </PlayerWrapper>
          <DescriptionWrapper>
            <Text dangerouslySetInnerHTML={{ __html: firstDescription }} />
          </DescriptionWrapper>
        </FirstSectionWrapper>
        <SecondSectionWrapper id="cfmoto-polska">
          <IntroWrapper>
            <StyledSubheading
              transform="uppercase"
              as="h3"
              weight={700}
              dangerouslySetInnerHTML={{ __html: secondSubheading }}
            />
            <StyledHeading
              size={lg ? 80 : 60}
              as="h2"
              transform="uppercase"
              line="100%"
              dangerouslySetInnerHTML={{ __html: secondHeading }}
            />
          </IntroWrapper>
          <PlayerWrapper>
            <Player videoSrcUrl={secondVideoUrl} />
          </PlayerWrapper>
          <DescriptionWrapper>
            <Text
              dangerouslySetInnerHTML={{ __html: secondDescription }}
              margin="50px"
            />
            <ButtonWithIcon as={Link} to="/znajdz-dealera" icon="pin">
              Znajdź dealera
            </ButtonWithIcon>
          </DescriptionWrapper>
        </SecondSectionWrapper>
      </Wrapper>
    </StyledContainer>
  )
}

export default About
