// node modules
import React from 'react'
import styled from 'styled-components'

// shared
import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazy-image'
import { Heading } from 'components/shared/typography'

// components
import ActionBar from 'components/pages/about/hero/action-bar'

// styles

import media from 'styles/media'

// types

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.section`
  ${media.md.min} {
    padding-bottom: 50px;
  }
`

const HeroWrapper = styled.div`
  height: calc(100vh + 50px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.md.max} {
    height: 100vh;
  }
`

const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const HeroContentWrapper = styled.div`
  position: relative;
  padding-top: 10vh;

  ${media.md.max} {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 50%;
    transform: translateX(-50%);
    top: 55%;
    width: 100%;

    h2,
    h3,
    h4 {
      padding: 0 35px;
    }

    a {
      margin: 0 50px;
      padding: 20px 35px;
      font-size: 16px;
    }
  }
`

type HeroProps = {
  image: {
    src: ImageDataLike
    alt: string
  }
  heading: string
}

const Hero: React.FC<HeroProps> = ({ image, heading }) => {
  return (
    <Wrapper id="hero">
      <HeroWrapper>
        <HeroBackground>
          <LazyImage src={image.src} alt={image.alt} fill loading="eager" />
        </HeroBackground>
        <Container>
          <HeroContentWrapper>
            <Heading
              as="h4"
              dangerouslySetInnerHTML={{ __html: heading }}
              transform="uppercase"
              themecolor="white"
              weight={900}
              line="100%"
            />
          </HeroContentWrapper>
        </Container>
      </HeroWrapper>
      <ActionBar />
    </Wrapper>
  )
}

export default Hero
