// node modules
import React from 'react'
import styled from 'styled-components'

// shared
import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'
import Icon from 'components/shared/icon'

// utils
import scrollToSection from 'utils/scrollToSection'

// styles
import media from 'styles/media'

// assets
import downloadIcon from 'assets/icons/download.svg'

const StyledContainer = styled(Container)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  ${media.md.max} {
    position: relative;
    left: 0;
    transform: translate(0, 0);
    padding: 0;
  }
`

const Wrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 0 40px;

  ${media.md.max} {
    flex-wrap: wrap;
    height: auto;
    padding: 0 1.35rem;
    align-items: flex-start;
  }
`

const StyledLink = styled.button`
  display: flex;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  border: none;
  background: none;
  padding: 25px 0;

  ${media.md.max} {
    &:last-of-type {
      border-top: 1px solid ${({ theme }) => theme.colors.gray};
    }
  }

  ${media.lg.min} {
    padding: 10px 0;
    width: 250px;
    &:not(&:nth-of-type(2)) {
      border-right: 1px solid ${({ theme }) => theme.colors.gray};
    }
  }
`

const StyledLinkWithIcon = styled.a`
  display: none;
  text-decoration: none;

  ${media.lg.min} {
    display: flex;
    align-items: center;
    position: absolute;
    right: 30px;

    span {
      margin-right: 10px;
    }
  }
`

const ActionBar: React.FC = () => {
  return (
    <>
      <StyledContainer>
        <Wrapper>
          <StyledLink onClick={() => scrollToSection('#cfmoto-global')}>
            <Text
              themecolor="white"
              margin={0}
              weight={700}
              size={17}
              transform="uppercase"
            >
              cfmoto global
            </Text>
          </StyledLink>
          <StyledLink onClick={() => scrollToSection('#cfmoto-polska')}>
            <Text
              themecolor="white"
              margin={0}
              weight={700}
              size={17}
              transform="uppercase"
            >
              cfmoto polska
            </Text>
          </StyledLink>
          <StyledLinkWithIcon href="https://cfmoto.pl/" target="_blank">
            <Icon src={downloadIcon} width={25} height={25} />
            <Text themecolor="white" margin={0} size={14} transform="uppercase">
              pobierz
              <br />
              broszurę
            </Text>
          </StyledLinkWithIcon>
        </Wrapper>
      </StyledContainer>
    </>
  )
}

export default ActionBar
